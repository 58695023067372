import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from './components/layout/header/Header';
import styles from './App.module.sass';
import './App.sass';
import Inici from './pages/inici/Inici';
import NavBar from './components/navbar/NavBar';
import Create from './pages/Create/Create';
import Esborrany from './pages/Esborrany/Esborrany';
import EnCurs from './pages/EnCurs/EnCurs';
import Publicats from './pages/Publicats/Publicats';
import Denegats from './pages/Denegats/Denegats';
import Detall from './pages/Detall/Detall';
import moment from 'moment';
import 'moment/locale/ca';
import IAppProps from './IAppProps';
import NotSignedIn from './pages/NotSignedIn/NotSignedIn';
moment.locale('ca');

function App(props: IAppProps) {

  const [ menuState, setMenuState ] = useState(true);
  const [ isMedia, setIsMedia ] = useState(false);
  const [ matches, setMatches ] = useState(null as any);
  
  const [ apiResult, setApiResult ] = useState("");
  const [ apiError, setApiError ] = useState("");

  window.addEventListener("resize", () => {
      if (window.matchMedia("(min-width: 1280px)").matches) {
        setIsMedia(false);
        setMenuState(true);
      } else {
        setIsMedia(true);
        setMenuState(false);
      }
  });  

  useEffect(() => {
    if (window.matchMedia("(min-width: 1280px)").matches) {
      setIsMedia(false);
      setMenuState(true);
    } else {
      setIsMedia(true);
      setMenuState(false);
    }
  }, []);

  const showMenu = () => {
    setMenuState(!menuState);
  }

  const invokeSignInEvent = () => {
    props.authServiceInstance.SignIn();
  }

  const invokeSignOutEvent = () => {
      props.authServiceInstance.SignOut();
  }

  if (props.authServiceInstance.account) {
    return (
      <div className="App">
          <div>
              <div>
                <Header userName={props.authServiceInstance.account.name} onClick={showMenu} logoutFunc={invokeSignOutEvent}/>
              </div>
              <div className={styles.contentGeneral}>
                <div className={styles.ContentSideBarMenu} >
                { menuState ? 
                  <NavBar 
                    apiService = {props.apiService}
                    openMenu = {() => {
                        if(isMedia == true) {
                          showMenu();
                        }
                    }} /> : null }
                </div>
                <div className={styles.contentDashboard}>
                  <Routes>
                    {/* <Route path="/" element={<Inici apiService={props.apiService} />}></Route> */}
                    <Route path="/" element={<EnCurs apiService={props.apiService}  />}></Route>
                    <Route path="/Create" element={<Create apiService={props.apiService}  />}></Route>
                    <Route path="/Esborrany" element={<Esborrany apiService={props.apiService}  />}></Route>
                    <Route path="/Esborrany/Detall" element={<Detall apiService={props.apiService}  />}></Route>
                    <Route path="/Esborrany/Editar" element={<Create apiService={props.apiService}  />}></Route>
                    <Route path="/EnCurs" element={<EnCurs apiService={props.apiService}  />}></Route>
                    <Route path="/EnCurs/Detall" element={<Detall apiService={props.apiService}  />}></Route>
                    <Route path="/Publicats" element={<Publicats apiService={props.apiService}  />}></Route>
                    <Route path="/Publicats/Detall" element={<Detall apiService={props.apiService}  />}></Route>
                    <Route path="/Denegats" element={<Denegats apiService={props.apiService}  />}></Route>
                    <Route path="/Denegats/Detall" element={<Detall apiService={props.apiService}  />}></Route>
                    {/* <Route path="/Denegats/Rectificar" element={<Create apiService={props.apiService} />}></Route> */}
                  </Routes>
                </div>
              </div>
            </div>
      </div>
    );
  }
  else {
    return (
      <div className="App">
          <NotSignedIn
              authServiceInstance={props.authServiceInstance} 
              loginButtonClicked={invokeSignInEvent} />
      </div>
  );
  }
}

export default App;
