import React from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import AlertInfoRejectedProps from './AlertInfoRejectedProps';

class AlertInfoRejected extends React.Component<AlertInfoRejectedProps, {}> {
    render() {
        return (
            <div className='alert alert-primary d-flex align-items-center' role="alert">
                <BsInfoCircleFill />
                <div className='ml-1' style={{paddingLeft: "10px"}}>
                    {this.props.message} <div dangerouslySetInnerHTML={{ __html: this.props.htmlValue }} />
                </div>
            </div>
        );
    }
}

export default AlertInfoRejected;