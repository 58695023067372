import React from 'react';
import BtnDeleteProps from './BtnDeleteProps';

//Bootstrap
import Button from 'react-bootstrap/Button';

//SCSS//
import styles from "./BtnDelete.module.sass";



class BtnDelete extends React.Component<BtnDeleteProps, {}> {
    render() {
        return (
            <div>
            <Button onClick={this.props.onClick} className={styles.BtnDelete}>
                {this.props.icon}
            </Button>
        </div>
        );
    }
}

export default BtnDelete;