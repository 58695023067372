import React from 'react';
import INotSignedInProps from './INotSignedInProps';

//IMAGES
import Logotipo from  "../../assets/icons/logotipo.svg";
import Ilustration from "../../assets/images/Login.png";

//SCSS//
import "./NotSignedIn.sass";
import BtnPrimary from '../../components/layout/buttons/primary/BtnPrimary';
import { BsArrowRight } from 'react-icons/bs';

class NotSignedIn extends React.Component<INotSignedInProps, {}> {
    render() {
        return (
            <div id='containerNotSigned'>
                <img src={Logotipo} className="logotipo" width={500}/>
                <div className='h2'>Iniciar sessió</div>
                <div className='p2'>Benvingut al portal publicador del BOPA.</div>
                <BtnPrimary onClick={this.props.loginButtonClicked} iconRight={<BsArrowRight />} text='Iniciar sessió'></BtnPrimary>
                <img src={Ilustration} className="ilustration" />
            </div>
        );
    }
}

export default NotSignedIn;