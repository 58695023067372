import BtnPrimaryProps from "./BtnPrimaryProps";
import styles from './BtnPrimary.module.sass';
import './BtnPrimary.sass';
import Button from 'react-bootstrap/Button';
import { Spinner } from "react-bootstrap";

function BtnPrimary(props:BtnPrimaryProps) {
    if(props.loading != true)
    {
        return(
            <Button name={props.name} type={props.type == "submit" ? "submit": "button"} variant="primary" className={styles.btn_primary} onClick={props.onClick} disabled={props.disabled}>
                <span id={props.text != null ? 'iconLeft' : '' }>{props.iconLeft}</span>
                {props.text}
                <span id={props.text != null ? 'iconRight' : '' }>{props.iconRight}</span>
            </Button>        
        )
    }
    else 
    {
        return(
            <Button name={props.name} type={props.type == "submit" ? "submit": "button"} variant="primary" className={styles.btn_primary} onClick={props.onClick} disabled={props.disabled}>
                {props.text}
                <span className={styles.loading}> <Spinner
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"/> </span>
            </Button>        
        )
    }
}

export default BtnPrimary