import React from "react";
import { Form, Row, Col, Tooltip, OverlayTrigger, FloatingLabel } from 'react-bootstrap';
import { Link } from "react-router-dom";
import BtnLink from "../layout/buttons/link/BtnLink";
import FormOutputProps from "./FormOutputProps";
import styles from "./FormsOutput.module.sass";
import "./FormsOutput.sass";
import { AiOutlineFileWord, AiOutlineFile, AiOutlineFilePdf, AiOutlineFileZip} from "react-icons/ai";

class FormOutput extends React.Component<FormOutputProps, {
    toggle: boolean;
}> 
  {
    constructor(props: any) {
      super(props);
      this.state = {
        toggle: false
      }
    }

    render () {
        return (
            <div>
                <p></p>
                {this.props.columns.map((item: any) => {
                return (
                    <div>
                        <div className={'caption-medium-outputForm'}>
                        {item.label}
                    </div>
                    {item.type == 'list' ? 
                        <div className={styles.ul_custom}>
                            <ul>
                            {item.value != null && item.value.length > 0 ? item.value.map((it: any) => {
                                return (
                                <li className="p2">{it.value}</li>
                                )                                
                            }): null }
                            </ul>      
                            {item.value != null && item.value.length == 0 ? '-' : null}                 
                        </div>
                    :
                    item.type == 'file' ?
                        <div className="p2">
                            {item.value != null ? 
                                <BtnLink iconLeft={item.value.fileName.split('.')[1].includes('doc') == true ? <AiOutlineFileWord /> : item.value.fileName.split('.')[1].includes('pdf') == true ? < AiOutlineFilePdf /> : item.value.fileName.split('.')[1].includes('zip') == true ? < AiOutlineFileZip /> : < AiOutlineFile />}text={item.value.fileName} path={item.value.fileUrl}/>
                            : '-' }  
                        </div>                  
                    :
                    item.type == 'files' ?
                        <div className="p2">
                            {item.value != null && item.value.length > 0 ? item.value.map((value: any) => {
                                return (
                                <BtnLink iconLeft={value.fileName.split('.')[1].includes('doc') == true ? <AiOutlineFileWord /> : value.fileName.split('.')[1].includes('pdf') == true ? < AiOutlineFilePdf /> : value.fileName.split('.')[1].includes('zip') == true ? < AiOutlineFileZip /> : < AiOutlineFile />}text={value.fileName} path={value.fileUrl}/>
                                )                                
                            }): '-' }  
                        </div>                  
                    :
                    item.type == 'html' ?
                    item.value != null ?
                        <div className="p2">
                            <div dangerouslySetInnerHTML={{ __html: item.value }} />
                        </div> 
                    : '-'                     
                    :
                        <div className="p2">
                            {item.value != null && item.value != '' ? item.value : '-'}                      
                        </div>
                    }
                        <p></p>
                    </div>
                )
              })}                
            </div>
        )
    }
}

export default FormOutput;