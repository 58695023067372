import PublicatsFilterProps from "./PublicatsFilterProps"
import Form from 'react-bootstrap/Form';

import { Col, Container, Row } from "react-bootstrap";
import BtnSecondary from "../../layout/buttons/secondary/BtnSecondary";
import {BsArrowRight} from 'react-icons/bs';
import BtnPrimary from "../../layout/buttons/primary/BtnPrimary";

import '../../../pages/Create/Create.sass';
import styles from './../Forms.module.sass';
import { useState } from "react";


function PublicatsFilter(props:PublicatsFilterProps) {

    let initState = {
        internalCode: "",
        summary: "",
        name: "",
        initDate: "",
        endDate: "",
        documentType: 0,
        createdBy:"",
        expInitDate:"",
        expEndDate:""
    }
    const [state, setState] = useState(initState);

    const handleChange = (evt:any) => {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    const handleClear = (evt:any) => {
        setState(initState);
        props.filterFunction(initState);
    }

    return (
        <div className={styles.contentFilterBG}>
                <Form className={styles.filterDesktop}>
                    <Row>
                        <Form.Group as={Col}>

                            <Form.Label>Codi intern</Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="Inserir..."
                            name="internalCode"
                            value={state.internalCode}
                            onChange={handleChange} />
                            <p></p>

                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Label>Data document des de</Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Label>Fins a </Form.Label>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col>
                                        <Form.Control
                                        type="date" 
                                        name="initDate"
                                        value={state.initDate}
                                        onChange={handleChange}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                        type="date"
                                        name="endDate"
                                        value={state.endDate}
                                        onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <p></p>

                            <Form.Label>Sumari</Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Inserir..." 
                                name="summary"
                                value={state.summary}
                                onChange={handleChange}
                                />
                            <p></p>

                            <Form.Label>Publicador</Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="Inserir..." /> 
                            <p></p>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Nom</Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="Inserir..."
                            name="name"
                            value={state.name}
                            onChange={handleChange}
                            /> 
                            <p></p>

                            <Form.Label>Tipus document</Form.Label>
                            <Form.Select
                            name="documentType"
                            onChange={handleChange}
                            value={state.documentType}
                            >
                                <option value={0}>Tots</option>
                                {props.documentTypeOptions?.map((item:any) => {
                                    return(
                                        <option value={item.id}>{item.value}</option>
                                    )
                                })}
                            </Form.Select>
                            <p></p>

                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Label>Data caducitat des de</Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Label>Fins a </Form.Label>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col>
                                        <Form.Control
                                        type="date" 
                                        name="expInitDate"
                                        value={state.expInitDate}
                                        onChange={handleChange}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                        type="date" 
                                        name="expEndDate"
                                        value={state.expEndDate}
                                        onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <p></p>

                        </Form.Group>

                    </Row>
                </Form>
                <Form className={styles.filterMobile}>
                    <Form.Label>Codi intern</Form.Label>
                    <Form.Control
                    type="text"
                    placeholder="Inserir..."
                    name="internalCode"
                    value={state.internalCode}
                    onChange={handleChange} />
                    <p></p>

                    <Form.Label>Data document des de</Form.Label>
                    <Form.Control
                    type="date" 
                    name="initDate"
                    value={state.initDate}
                    onChange={handleChange}
                    />
                    <p></p>
                    <Form.Label>Fins a </Form.Label>
                    <Form.Control
                    type="date"
                    name="endDate"
                    value={state.endDate}
                    onChange={handleChange}
                    />
                    <p></p>

                    <Form.Label>Sumari</Form.Label>
                        <Form.Control
                        type="text"
                        placeholder="Inserir..." 
                        name="summary"
                        value={state.summary}
                        onChange={handleChange}
                        />
                    <p></p>

                    <Form.Label>Publicador</Form.Label>
                    <Form.Control
                    type="text"
                    placeholder="Inserir..." /> 
                    <p></p>
                    <Form.Label>Nom</Form.Label>
                    <Form.Control
                    type="text"
                    placeholder="Inserir..."
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                    /> 
                    <p></p>

                    <Form.Label>Tipus document</Form.Label>
                    <Form.Select
                    name="documentType"
                    onChange={handleChange}
                    value={state.documentType}
                    >
                        <option value={0}>Tots</option>
                        {props.documentTypeOptions?.map((item:any) => {
                            return(
                                <option value={item.id}>{item.value}</option>
                            )
                        })}
                    </Form.Select>
                    <p></p>
                    <Form.Label>Data caducitat des de</Form.Label>
                    <Form.Control
                    type="date" 
                    name="expInitDate"
                    value={state.expInitDate}
                    onChange={handleChange}
                    />
                    <p></p>
                    <Form.Label>Fins a </Form.Label>
                    <Form.Control
                    type="date" 
                    name="expEndDate"
                    value={state.expEndDate}
                    onChange={handleChange}
                    />
                </Form>
                <p></p>
                <hr></hr>

                <div className={styles.containerBtns}>
                    <div>
                    </div>
                    <div className={styles.containerBtns2}>
                        <div className={styles.buttonCustom}>
                            <BtnSecondary onClick={handleClear} text="Netejar" />
                        </div>
                        <div className={styles.buttonCustom}>
                            <BtnPrimary onClick={() => { props.filterFunction(state)}} text="Cercar " iconRight={<BsArrowRight />}/>
                        </div>
                    </div>
                </div>                        
            </div>     
    )
}

export default PublicatsFilter