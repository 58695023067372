import BtnDefaultProps from "./BtnDefaultProps"
import styles from './BtnDefault.module.sass'
import Button from 'react-bootstrap/Button'

function BtnDefault(props:BtnDefaultProps) {
    return(
        <Button variant="primary" className={styles.btn_primary} onClick={props.onClick}>
            <span>{props.iconLeft}</span>
            {props.text}
            <span>{props.iconRight}</span>
        </Button>        
    )
}

export default BtnDefault