import { Col, Container, Dropdown, DropdownButton } from "react-bootstrap"
import { BsThreeDotsVertical, BsTrash, BsPencil, BsFileEarmarkText, BsEye, BsClock} from "react-icons/bs"
import '../../styles/Table.sass'
import styles from './EnCurs.module.sass'
import { useEffect, useState } from "react"
import TableMenu from "../../components/table/TableMenu/TableMenu"
import BtnLink from "../../components/layout/buttons/link/BtnLink"
import{ TfiReload } from 'react-icons/tfi'
import EnCursFilter from "../../components/forms/EnCursFilter/EnCursFilter"
import axios from "axios";
import loadingLogo from '../../assets/loading/loading.gif';
import moment from "moment"
import EnCursProps from "./EnCursProps"
import { SlPaperPlane } from 'react-icons/sl'

function EnCurs(props: EnCursProps) {

    const [minutesAgo, setminutesAgo] = useState(0);
    const [resources, setResources] = useState([] as any);
    const [loading, setLoading] = useState(0); // integer state
    const [filterData, setFilterData] = useState([] as any);
    const [isSupervisor, setIsSupervisor] = useState(false);

    useEffect(() => {
        props.apiService.GetPendingDocuments()
        .then((response: any) => {
            let auxData: any[] = [];
            setIsSupervisor(response.data.isSupervisor);
            response.data.documents.map((item:any) => {
                if(item.id != null) {
                    auxData.push({
                        code: item.internalCode != null ? item.internalCode : "", 
                        document: {
                            id: parseInt(item.id),
                            name: item.name,
                        },           
                        summary: item.summary != null ? item.summary : "",
                        documentType: item.documentType,
                        documentDate: item.documentDate,
                        state: item.state,
                        display: true,
                        createdBy: item.createdBy,
                        creationDate: item.creationDate
                    })
                }                
            })
            auxData = auxData.sort((a,b) => {
                return a.document.id - b.document.id
            }).reverse();
            setResources(auxData);
            setLoading(1);
            props.apiService.GetTaxonomyFields()
            .then((response: any) => {
                setFilterData(response.data);
            })
            .catch((error: any) => {
                console.log(error)
            })
        })
        .catch((error: any) => {
            console.log(error)
        })        
    }, []);

    const normalizeTxt = (str: any) => {
        str = str.toUpperCase();
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      } 

    const filter = (filters: any) => {
        let auxEvents = [...resources];
        auxEvents.map((event:any) => {
            if (filters.name != null && filters.name != '' && normalizeTxt(event.document.name).includes(normalizeTxt(filters.name)) == false)
            {
                event.display = false;
            }
            else if (filters.summary != null && filters.summary != '' && normalizeTxt(event.summary).includes(normalizeTxt(filters.summary)) == false)
            {
                event.display = false;
            }
            else if (filters.internalCode != null && filters.internalCode != '' && normalizeTxt(event.code).includes(normalizeTxt(filters.internalCode)) == false)
            {
                event.display = false;
            }
            else if (filters.documentType != null && filters.documentType != 0 && (event.documentType == null || event.documentType != null && parseInt(filters.documentType) != event.documentType.id))
            {
                event.display = false;
            }
            else if (filters.initDate != null && (event.documentDate == null || moment(filters.initDate).diff(moment(event.documentDate).format('YYYY-MM-DD'), 'days') > 0 ))
            {
                event.display = false;
            }
            else if (filters.endDate != null && (event.documentDate == null || moment(filters.endDate).diff(moment(event.documentDate).format('YYYY-MM-DD'), 'days') < 0 ))
            {
                event.display = false;
            }
            else if (filters.stateType != null && filters.stateType != '' && event.state != filters.stateType)
            {
                event.display = false;
            }
            else if (filters.createdBy != null && filters.createdBy != '' && (event.createdBy == null || event.createdBy.value == null ||event.createdBy.value == "" || normalizeTxt(event.createdBy.value).includes(normalizeTxt(filters.createdBy)) == false))
            {
                event.display = false;
            }
            else
            {
                event.display = true;
            }
        })
        setResources(auxEvents);
    }


    const columns: any[] = [
        {
            title: 'Codi intern',
            dataIndex: 'code',
            width: '250px',
            sorter: (a: any, b: any) => a.code.localeCompare(b.code),
        },
        {
            title: 'Nom',
            dataIndex: 'document',
            width: '250px',
            sorter: (a: any, b: any) => a.document.name.localeCompare(b.document.name),
            render: (document: any) => (
            <BtnLink state={{id: document.id}} path={`/EnCurs/Detall`} text={document.name}></BtnLink>
            )
        },        
        {
            title: 'Estat',
            dataIndex: 'state',
            width: '280px',
            key: 3,
            sorter: (a: any, b: any) => a.state.localeCompare(b.state),
            render: (state: any) => (
                <div>{ state == 'Enviat' ? <SlPaperPlane color={'#198753'}/> : state == 'Pendent de firma' ? <BsPencil color={'#FFC007'}/> : state == 'Pendent de revisió' ? <BsEye color={'#0D6EFD'}/> : state == 'Pendent de publicar' ? <BsClock color={'#198753'} /> : null}{' '}{state}</div>
                )
        },
        {
            title: 'Sumari',
            dataIndex: 'summary',
            width: '500px',
            key: 4,
            sorter: (a: any, b: any) => a.summary.localeCompare(b.summary),
        },
        {
            title: 'Publicador',
            dataIndex: 'createdBy',
            width: '180px',
            key: 7,
            sorter: (a: any, b: any) => a.createdBy.value.localeCompare(b.createdBy.value),
            render: (createdBy: any) => (
                <div>{createdBy.value != null ? createdBy.value : ""}</div>
                ),
            hidden: (isSupervisor == true ? false : true),
        }
    ];
    
    if(loading != 0){
    return (
        <Container>
            <div className={styles.titleContainer}>
                <Col><div className='h2'>En curs</div></Col>
                <Col>
                    <div className={styles.btnRefresh}>
                        {minutesAgo == 0 ? null : <BtnLink path='/Esborrany' iconLeft={<TfiReload />}
                        text={' Actualitzat fa '+ minutesAgo +' minuts '}/> }
                    </div>
                </Col>                
                <Col></Col>
            </div>
            <div className={styles.contentCompBG}>
                <TableMenu childFilter={<EnCursFilter filterFunction={filter} documentTypeOptions={filterData.tipusDocument} />} resourceData={resources.filter((x:any) => x.display === true)} columns={columns.filter(item => !item.hidden)} />
            </div>
        </Container>
    )
    }
    else {
        return (
            <Container>
            <div className={styles.titleContainer}>
                <Col><div className='h2'>En curs</div></Col>
                <Col></Col>                
                <Col></Col>
            </div>
            <div className={styles.contentCompBG}>
                <div className="app-spinner">
                        <img width={150} src={loadingLogo} alt="loading..." />
                    </div>
            </div>
            </Container> 
        );
    }
}

export default EnCurs