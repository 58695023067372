import { Col, Container, Modal } from "react-bootstrap";
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary";
import BtnSecondary from "../../components/layout/buttons/secondary/BtnSecondary";
import BtnDefault from "../../components/layout/buttons/default/BtnDefault";
import {BsArrowRight} from 'react-icons/bs';

//Styles
import '../../styles/Table.sass';
import styles from './Detall.module.sass';
import { useEffect, useState } from "react";
import BtnLink from "../../components/layout/buttons/link/BtnLink";
import{ TfiReload } from 'react-icons/tfi';
import { Link, useLocation } from "react-router-dom";
import FormOutput from "../../components/FormOutput/FormOutput";
import axios, { AxiosInstance } from 'axios';
import loadingLogo from '../../assets/loading/loading.gif';
import moment from "moment";
import AlertWarning from "../../components/Alerts/AlertWarning/AlertWarning";
import DetallProps from "./DetallProps";


function Detall(props: DetallProps) {

    const [data, setData] = useState([] as any);
    const [loading, setLoading] = useState(0); // integer state
    const [minutesAgo, setminutesAgo] = useState(0);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let { state } = useLocation();

    useEffect(() => {
        props.apiService.GetDocumentById(state.id)
        .then((response: any) => {
            setData(response.data);
            setLoading(1);        
        })
        .catch((error: any) => {
            console.log(error)
        })
    }, []);

    const DeleteDocumentFunction = (id: number) => {
        setShow(false);
        setLoading(0);
        props.apiService.DeleteDocument(id)
        .then((response: any) => {
            window.open('/Esborrany', '_self')      
        })
        .catch((error: any) => {
            console.log(error)
        })
    }

    let page = window.location.pathname.split('/')[1];

    if(loading != 0){
        return (        
            <Container>
                <div className={styles.titleContainer}>
                    <Col><div className='h2'>Detall document</div></Col>
                    <Col>
                        <div className={styles.btnRefresh}>
                            {minutesAgo == 0 ? null : <BtnLink path='/Esborrany' iconLeft={<TfiReload />}
                            text={' Actualitzat fa '+ minutesAgo +' minuts '}/> }
                        </div>
                    </Col>                
                    <Col></Col>
                </div>
                    <div className={styles.contentCompBG}>
                        <div className='p1'>Dades</div>
                        <hr />
                        <FormOutput columns={[{
                            label: 'Codi intern del document',
                            value:  data?.internalCode
                        },
                        {
                            label: 'Tipus document',
                            value: data?.documentType?.value
                        },
                        {
                            label: 'Nom',
                            value: data?.name
                        },
                        {
                            label: 'Sumari',
                            value: data?.summary
                        }]}/>

                        <FormOutput columns={[
                        {
                            label: 'Cos del document',
                            value: data?.document,
                            type: 'file'
                        },
                        {
                            label: 'Annex',
                            value: data?.annexes,
                            type: 'files'
                        },
                        ]}/>
                        <FormOutput columns={[
                        {
                            label: 'Data document',
                            value: moment(data?.documentDate).format('LL') 
                        },
                        {
                            label: 'Tema',
                            value: data?.issue?.value
                        },
                        {
                            label: 'Firmants del document',
                            value: data?.signatories,
                            type:'list'
                        },
                        {
                            label: 'Firma en paper',
                            value: data != null && data.paperSign != null && data.paperSign == true ? 'Sí' : 'No' 
                        },
                        {
                            label: "Nombre de dies d'exposició pública",
                            value: data?.expositionDays
                        },
                        {
                            label: 'Document de referència',
                            value: data?.referenceDocument
                        },
                        {
                            label: 'BOPA extra',
                            value: data != null && data.extraBOPA != null && data.extraBOPA == true ? 'Sí' : 'No' 
                        },
                        {
                            label: 'Comentaris',
                            value: data?.comments
                        },
                        {
                            label: 'Codi CELEX',
                            value: data?.celexCode
                        },
                        {
                            label: 'Estat',
                            value: data?.state
                        }
                        ]}/>
                        { page == 'Denegats' ?
                            <FormOutput columns={[
                            {
                                label: 'Motiu rebuig',
                                value: data?.rejectReason,
                                type: 'html'
                            },
                            // {
                            //     label: 'Rebuig per',
                            //     value: data?.rejectedBy?.value
                            // }
                            ]}/> : null
                        }
                        
                        <FormOutput columns={[
                        // {
                        //     label: 'Creat',
                        //     value: moment(data?.creationDate).format('LLL')
                        // },
                        {
                            label: 'Publicador',
                            value: data?.createdBy?.value
                        },
                        // {
                        //     label: 'Darrera modificació',
                        //     value: data?.modificationDate
                        // },
                        // {
                        //     label: 'Darrera modificació per',
                        //     value: data?.modificatedBy
                        // }
                        ]}/>
                        <hr />

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                            <Modal.Title>Suprimeix</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <AlertWarning message="Està segur de voler eliminar el document?" />
                            </Modal.Body>
                            <Modal.Footer>
                            <BtnPrimary text="Suprimeix ara" iconRight={<BsArrowRight />} onClick={ () => { DeleteDocumentFunction(state.id) } } />
                            </Modal.Footer>
                        </Modal>
    
                        <div className={styles.containerBtns}>
                            <div className={styles.buttonCustom}>
                            <BtnDefault text={"Tornar"} onClick={()=>{window.open('/' + page, '_self')}}></BtnDefault>
                            </div>
                            <div className={styles.containerBtns2}>
                                <div className={styles.buttonCustom}>
                                    <BtnSecondary onClick={handleShow} text={"Supremeix"} disabled={page=='Esborrany' ? false : true}></BtnSecondary>
                                </div>
                                <div className={styles.buttonCustom}>
                                    <Link to={page == 'Esborrany' ? "/Esborrany/Editar" : ""} state={{id: state.id}}  className={styles.link} >
                                        <BtnPrimary text={"Editar "} iconRight={<BsArrowRight />} disabled={page == 'Esborrany' ? false : true}></BtnPrimary> 
                                        {/* <BtnPrimary text={page=='Denegats' ? "Rectificar " : "Editar "} iconRight={<BsArrowRight />} disabled={page == 'Esborrany' || page=='Denegats' ? false : true}></BtnPrimary>  */}
                                    </Link>
                                </div>
                            </div>                        
                        </div>  
                    </div>
            </Container>
                   
        )
    }
    else {
        return (
            <Container>
                <div className={styles.titleContainer}>
                    <Col><div className='h2'>Detall document</div></Col>
                    
                    <Col>
                        <div className={styles.btnRefresh}>
                            {minutesAgo == 0 ? null : <BtnLink path='/Esborrany' iconLeft={<TfiReload />}
                            text={' Actualitzat fa '+ minutesAgo +' minuts '}/> }
                        </div>
                    </Col>                
                    <Col></Col>
                </div>
                <div className={styles.contentCompBG}>
                    <div className="app-spinner">
                        <img width={150} src={loadingLogo} alt="loading..." />
                    </div>
                </div>            
            </Container>
        );
    }

}

export default Detall