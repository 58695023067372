import { AuthenticationResult } from '@azure/msal-browser';
import axios, { AxiosInstance } from 'axios';
import moment from 'moment';
import AuthService from './AuthService';

class ApiService {
    // constructor that requires a base URI.
    constructor(baseUri: string, authService: AuthService) {
        if (!baseUri) {
            throw new Error('the base uri was not provided');
        }
        if (!authService) {
            throw new Error('the auth service was not provided');
        }

        this.AuthorizationService = authService;

        this.AuthenticatedApi = axios.create({
            baseURL: baseUri,
            // will inject auth header on-demand later as needed.
            headers: { 
                'Content-Type': 'application/json',
            }
        });
    }

    // the authenticated api
    AuthenticatedApi: AxiosInstance;

    // the authorization service
    // wrapper around MSAL
    AuthorizationService: AuthService;

    // an api operation that calls one of the anonymous / no-auth endpoints.
    InvokeNoAuthApiCall() {
        return this.AuthenticatedApi.get('/test/noauth')
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                throw Error('An error has occurred calling the api: ' + error);
            });
    }

    InvokeUserApiCall() {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.get('/test/UserAccess', {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response.data;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }

    // an api operation that calls one of the admin user authorized endpoints.
    InvokeAdminApiCall() {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.get('/test/admin', {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response.data;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }


    ///////// DEV ////////////////
    // GetLastDocuments () {
    //     return this.AuthorizationService.GetToken()
    //         .then((response: AuthenticationResult) => {
    //             return this.AuthenticatedApi.get(`/api/GetLastDocuments?code=rYDCsWUOYeHHfqyT0Ulmkl4JKQCidHaqIW-1ZVb3LwsuAzFuJJfhXw==`, 
    //             {
    //                 headers: {
    //                     Authorization: 'Bearer ' + response.accessToken
    //                 }
    //             })
    //             .then((response: any) => {
    //                 return response.data;
    //             })
    //             .catch((error: any) => {
    //                 throw Error('An error has occurred calling the web api: ' + error);
    //             });
    //         })
    //         .catch((error: any) => {
    //             throw Error('An error has occurred: ' + error);
    //         });
    // }

    // GetRejectedDocuments () {
    //     return this.AuthorizationService.GetToken()
    //         .then((response: AuthenticationResult) => {
    //             return this.AuthenticatedApi.get(`/api/GetRejectedDocuments?code=I8nNPUnB_FUxQxfICSxW7iqs1fsECOgtq1yAhS2f2qNhAzFuG4fotw==`, 
    //             {
    //                 headers: {
    //                     Authorization: 'Bearer ' + response.accessToken
    //                 }
    //             })
    //             .then((response: any) => {
    //                 return response;
    //             })
    //             .catch((error: any) => {
    //                 throw Error('An error has occurred calling the web api: ' + error);
    //             });
    //         })
    //         .catch((error: any) => {
    //             throw Error('An error has occurred: ' + error);
    //         });
    // }

    // GetTaxonomyFields () {
    //     return this.AuthorizationService.GetToken()
    //         .then((response: AuthenticationResult) => {
    //             return this.AuthenticatedApi.get(`/api/GetTaxonomyFields?code=CHpJ-XiS6GIxMkeC3okBe2myDlZjC7ihBKwISbu81E5ZAzFuxqQqTA==`, 
    //             {
    //                 headers: {
    //                     Authorization: 'Bearer ' + response.accessToken
    //                 }
    //             })
    //             .then((response: any) => {
    //                 return response;
    //             })
    //             .catch((error: any) => {
    //                 throw Error('An error has occurred calling the web api: ' + error);
    //             });
    //         })
    //         .catch((error: any) => {
    //             throw Error('An error has occurred: ' + error);
    //         });
    // }

    // GetDrafts () {
    //     return this.AuthorizationService.GetToken()
    //         .then((response: AuthenticationResult) => {
    //             return this.AuthenticatedApi.get(`/api/GetDraftDocuments?code=eGfTKfP9dvvv2PYhK3MMntBMkKhYiB2ZebFxYDa3P7rnAzFupC2J3A==`, 
    //             {
    //                 headers: {
    //                     Authorization: 'Bearer ' + response.accessToken
    //                 }
    //             })
    //             .then((response: any) => {
    //                 return response;
    //             })
    //             .catch((error: any) => {
    //                 throw Error('An error has occurred calling the web api: ' + error);
    //             });
    //         })
    //         .catch((error: any) => {
    //             throw Error('An error has occurred: ' + error);
    //         });
    // }

    // DeleteDocument(id: number)  {
    //     return this.AuthorizationService.GetToken()
    //         .then((response: AuthenticationResult) => {
    //             return this.AuthenticatedApi.get(`/api/DeleteDocument?code=PkCtN17hjlZZrdyxHU4nniaXgHLnoZnry-mlAAJ1ti-PAzFu9g6JZw==&id=${id}`, 
    //             {
    //                 headers: {
    //                     Authorization: 'Bearer ' + response.accessToken
    //                 }
    //             })
    //             .then((response: any) => {
    //                 return response;
    //             })
    //             .catch((error: any) => {
    //                 throw Error('An error has occurred calling the web api: ' + error);
    //             });
    //         })
    //         .catch((error: any) => {
    //             throw Error('An error has occurred: ' + error);
    //         });
    // }

    // GetPendingDocuments()  {
    //     return this.AuthorizationService.GetToken()
    //         .then((response: AuthenticationResult) => {
    //             return this.AuthenticatedApi.get(`/api/GetPendingDocuments?code=S4EZtnWRV-37Pe7F1UUva-JQGWo23od_1mVCycLMIBrdAzFucG1BKg==`, 
    //             {
    //                 headers: {
    //                     Authorization: 'Bearer ' + response.accessToken
    //                 }
    //             })
    //             .then((response: any) => {
    //                 return response;
    //             })
    //             .catch((error: any) => {
    //                 throw Error('An error has occurred calling the web api: ' + error);
    //             });
    //         })
    //         .catch((error: any) => {
    //             throw Error('An error has occurred: ' + error);
    //         });
    // }

    // GetDocumentById(id: number)   {
    //     return this.AuthorizationService.GetToken()
    //         .then((response: AuthenticationResult) => {
    //             return this.AuthenticatedApi.get(`/api/GetDocumentsById?code=cM5mEdxoPdq0UERMns0x8o0lvQOJn91sozBTcJP_L0-KAzFuMxMZ5g==&&id=${id}`, 
    //             {
    //                 headers: {
    //                     Authorization: 'Bearer ' + response.accessToken
    //                 }
    //             })
    //             .then((response: any) => {
    //                 return response;
    //             })
    //             .catch((error: any) => {
    //                 throw Error('An error has occurred calling the web api: ' + error);
    //             });
    //         })
    //         .catch((error: any) => {
    //             throw Error('An error has occurred: ' + error);
    //         });
    // }

    // CreateDraft(post: any) {
    //     return this.AuthorizationService.GetToken()
    //         .then((response: AuthenticationResult) => {
    //             return this.AuthenticatedApi.post(`/api/CreateDraft?code=kYcW-tpojEWBJt0VSLVerJ28HYa_q4seRAtnMBeHnG2zAzFuSjBGUQ==`, 
    //             post,
    //             {
    //                 headers: {
    //                     Authorization: 'Bearer ' + response.accessToken
    //                 }
    //             })
    //             .then((response: any) => {
    //                 return response;
    //             })
    //             .catch((error: any) => {
    //                 throw Error('An error has occurred calling the web api: ' + error);
    //             });
    //         })
    //         .catch((error: any) => {
    //             throw Error('An error has occurred: ' + error);
    //         });
    // }

    // GetPublishedDocuments ()   {
    //     return this.AuthorizationService.GetToken()
    //         .then((response: AuthenticationResult) => {
    //             return this.AuthenticatedApi.get(`/api/GetPublishedDocuments?code=EhBZRza_efvTKuU4kmudh-8n7bLTBiVGm8qj2GrSZVdcAzFugD1bOw==`, 
    //             {
    //                 headers: {
    //                     Authorization: 'Bearer ' + response.accessToken
    //                 }
    //             })
    //             .then((response: any) => {
    //                 return response;
    //             })
    //             .catch((error: any) => {
    //                 throw Error('An error has occurred calling the web api: ' + error);
    //             });
    //         })
    //         .catch((error: any) => {
    //             throw Error('An error has occurred: ' + error);
    //         });
    // }

    // SendDraft (id: number) {
    //     return this.AuthorizationService.GetToken()
    //         .then((response: AuthenticationResult) => {
    //             return this.AuthenticatedApi.get(`/api/SendDraft?code=GVMJp9AyWei5JP7XsHm5pdFwPf5NaWVsUvd7X0eDGcZgAzFu7ZTdaA==&idDraft=` + id, 
    //             {
    //                 headers: {
    //                     Authorization: 'Bearer ' + response.accessToken
    //                 }
    //             })
    //             .then((response: any) => {
    //                 return response;
    //             })
    //             .catch((error: any) => {
    //                 throw Error('An error has occurred calling the web api: ' + error);
    //             });
    //         })
    //         .catch((error: any) => {
    //             throw Error('An error has occurred: ' + error);
    //         });
    // }

    // GetDocumentsCounters () {
    //     return this.AuthorizationService.GetToken()
    //         .then((response: AuthenticationResult) => {
    //             return this.AuthenticatedApi.get(`/api/GetDocumentsCounters?code=d7PK4aQ73bV0xxzCV-fTgJeXluaSAAZN-cbpspZeJ1E1AzFuz-OUzw==`, 
    //             {
    //                 headers: {
    //                     Authorization: 'Bearer ' + response.accessToken
    //                 }
    //             })
    //             .then((response: any) => {
    //                 return response;
    //             })
    //             .catch((error: any) => {
    //                 throw Error('An error has occurred calling the web api: ' + error);
    //             });
    //         })
    //         .catch((error: any) => {
    //             throw Error('An error has occurred: ' + error);
    //         });
    // }

    /////////PROD////////////////
    GetLastDocuments () {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.get(`/api/GetLastDocuments?code=AcY6pmdn6H7SO-AOxOzcX2MvVrHA_OzmHhPhWRlwMh6IAzFuykjC5w==`, 
                {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response.data;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }

    GetRejectedDocuments () {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.get(`/api/GetRejectedDocuments?code=ywkIB_TCSk8qktHDK8TLX3EZYSsootZ-yu8S2rRzTImyAzFuxld5sQ==`, 
                {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }

    GetTaxonomyFields () {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.get(`/api/GetTaxonomyFields?code=Ja2BYcU8SfrGG5fr9CR1VYczfwSzQUp89WdPfK0nLxrQAzFux_39ag==`, 
                {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }

    GetDrafts () {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.get(`/api/GetDraftDocuments?code=XqZo0m2lU6Djo_Ag7i4VkAyG-u1tQO2MBkSw7d3iLlknAzFuxTHVlQ==`, 
                {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }

    DeleteDocument(id: number)  {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.get(`/api/DeleteDocument?code=Km2Ei7IA3rQPiVLxcnsb7Lio4zggBAZ_Du3kLIuYGu3zAzFuDIXHQA==&id=${id}`, 
                {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }

    GetPendingDocuments()  {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.get(`/api/GetPendingDocuments?code=v2dtPI4jaGEPx-6NLHcS0GT7XtMf5WeDi7PzF_azJeKEAzFupAXlvg==`, 
                {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }

    GetDocumentById(id: number)   {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.get(`/api/GetDocumentsById?code=1JItBuBokWLIMCjvaW91BjU7YLQHL4spFEp32We1oWe9AzFuZBAqbA==&&id=${id}`, 
                {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }

    CreateDraft(post: any) {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.post(`/api/CreateDraft?code=oWjREWWixgqSUg2wue32trgaCRctGAHKSAhxuGlyvTbwAzFu2M1u_Q==`, 
                post,
                {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    const jsonString = JSON.stringify(post);
                    const bodySizeInBytes = new Blob([jsonString]).size;
                    if(bodySizeInBytes > 104857600){
                        throw Error("La mida dels fitxer no pot ser superior a 100 MB.")
                    }
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                const jsonString = JSON.stringify(post);
                const bodySizeInBytes = new Blob([jsonString]).size;
                if(bodySizeInBytes > 104857600){
                    throw Error("La mida dels fitxer no pot ser superior a 100 MB.")
                }
                throw Error('An error has occurred: ' + error);
            });
    }

    GetPublishedDocuments ()   {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.get(`/api/GetPublishedDocuments?code=VKv1FD-Os5a6wbjNjvy1O4zGWe59xbrU-pWSH44FZcSzAzFu3qnhYQ==`, 
                {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }

    SendDraft (id: number) {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.get(`/api/SendDraft?code=kxO2RmYVEOChKQODt16n2eWv7x1DLnV4_eehz2mtmqsIAzFuBnAJcg==&idDraft=` + id, 
                {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }

    GetDocumentsCounters () {
        return this.AuthorizationService.GetToken()
            .then((response: AuthenticationResult) => {
                return this.AuthenticatedApi.get(`/api/GetDocumentsCounters?code=4wNbjQ0dGSVenst340Eyd_wmw9lxGdpxI6CPA020cUapAzFumo5i6A==`, 
                {
                    headers: {
                        Authorization: 'Bearer ' + response.accessToken
                    }
                })
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the web api: ' + error);
                });
            })
            .catch((error: any) => {
                throw Error('An error has occurred: ' + error);
            });
    }
}

export default ApiService;