import BtnLinkProps from "./BtnLinkProps";
import styles from './BtnLink.module.sass';
import Button from 'react-bootstrap/Button';
import { Link, useLocation } from "react-router-dom";

function BtnLink(props:BtnLinkProps) {
    return (
        <Link to={props.path} state={props.state} className={styles.btn_link} target={props.target != null  ? props.target : '_self'}>
            <Button className={styles.btn_link}>
                <span>{props.iconLeft}</span>&nbsp;
                <span>{props.text}</span>&nbsp;
                <span>{props.iconRight}</span>
            </Button>
        </Link>        
    )
}

export default BtnLink