// import PublicatsProps from "./PublicatsProps"
import { Col, Container, Dropdown, DropdownButton } from "react-bootstrap"
import { BsThreeDotsVertical, BsTrash, BsPencil, BsFileEarmarkText, BsEye, BsClock} from "react-icons/bs"

//Styles
import '../../styles/Table.sass'
import styles from './Publicats.module.sass'
import { useEffect, useState } from "react"
import TableMenu from "../../components/table/TableMenu/TableMenu"
import BtnLink from "../../components/layout/buttons/link/BtnLink"
import{TfiReload} from 'react-icons/tfi'
import { Badge, Table, TableColumnsType } from "antd"
import PublicatsFilter from "../../components/forms/PublicatsFilter/PublicatsFilter"
import axios from "axios";
import loadingLogo from '../../assets/loading/loading.gif';
import moment from "moment";
import "./Publicats.sass";
import PublicatsProps from "./PublicatsProps"


function Publicats(props: PublicatsProps) {

    const [minutesAgo, setminutesAgo] = useState(0);
    const [resources, setResources] = useState([] as any);
    const [loading, setLoading] = useState(0); // integer state
    const [filterData, setFilterData] = useState([] as any);
    const [isSupervisor, setIsSupervisor] = useState(false);

    useEffect(() => {
        props.apiService.GetPublishedDocuments()
        .then((response: any) => {
            setIsSupervisor(response.data.isSupervisor);
            let auxData: any[] = [];
            let i: number = 1;
            response.data.butlletins.map((doc: any) => {
                let children = doc.documents.map((child: any) => ({
                    internalCode: child.internalCode,
                    document: {
                        name: child.name,
                        id: child.id
                    },
                    documentType: child.documentType,
                    documentLink: "https://www.bopa.ad/Documents/Detall?doc=" + child.name,
                    summary: child.summary,
                    createdBy: child.createdBy,
                    expirationDate: child.expirationDate,
                    documentDate: child.documentDate,
                    display: true,
                }))

                auxData.push({
                    documentName: doc.name,
                    key: i,
                    children: children,
                    display: true,
                    publicationDate: doc.publicationDate,
                })
                i++;
            })

            auxData = auxData.sort((a,b) => {
                return new Date(a.publicationDate).getTime() - new Date(b.publicationDate).getTime()
            }).reverse();

            setResources(auxData);
            setLoading(1);

            props.apiService.GetTaxonomyFields()
            .then((response: any) => {
                setFilterData(response.data);
            })
            .catch((error: any) => {
                console.log(error)
            })
        })
        .catch((error: any) => {
            console.log(error)
        })        
    }, []);

    const normalizeTxt = (str: any) => {
        str = str.toUpperCase();
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 

    const columns: any[] = [
        {
            title: '',
            dataIndex: 'documentName',
            key:'documentName'                
        }
    ];

    const expandedRowRender = (record: any) => {
        const subColumns: any[] = [
            {
                title: 'Codi intern',
                dataIndex: 'internalCode',
                width: '250px',
                //sorter: (a: any, b: any) => a.internalCode.localeCompare(b.internalCode),
            },
            {
                title: 'Nom',
                dataIndex: 'document',
                width: '250px',
                //sorter: (a: any, b: any) => a.document.name.localeCompare(b.document.name),
                render: (document: any) => (
                    <div>{document.name}</div>
                // <BtnLink state={{id: document.id}} path={`/Publicats/Detall`} text={document.name}></BtnLink>
                )
            }, 
            // {
            //     title: 'Butlletí',
            //     dataIndex: 'butlleti',
            //     width: '220px',
            //     sorter: (a: any, b: any) => a.butlleti.localeCompare(b.butlleti),
            //     render: (butlleti: any) => (
            //     <BtnLink path={`/Publicats`} text={butlleti}></BtnLink>
            //     )
            // }, 
            {
                title: 'Enllaç al doc. publicat',
                dataIndex: 'documentLink',
                width: '220px',
                render: (documentLink: any) => {
                    if(documentLink != '') {
                        return ( <BtnLink target="_blank" path={documentLink} text={'Enllaç'}></BtnLink> )
                        
                    } else {
                        return ( <div> Despublicat </div> )
                    }                
                }
            },
            // {
            //     title: 'Tipus document',
            //     dataIndex: 'type',
            //     width: '180px',
            //     sorter: (a: any, b: any) => a.type.localeCompare(b.type),
            // },
            // {
            //     title: 'Data document',
            //     dataIndex: 'date',
            //     width: '180px',
            //     sorter: (a: any, b: any) => a.date.localeCompare(b.date),
            // },   
            {
                title: 'Sumari',
                dataIndex: 'summary',
                width: '500px',
                //sorter: (a: any, b: any) => a.summary.localeCompare(b.summary),
            },
            {
                title: 'Publicador',
                dataIndex: 'createdBy',
                width: '180px',
                sorter: (a: any, b: any) => a.createdBy.value.localeCompare(b.createdBy.value),
                render: (createdBy: any) => (
                    <div>{createdBy.value != null ? createdBy.value : ""}</div>
                    ),
                hidden: (isSupervisor == true ? false : true),
            },
            {
                title: 'Data caducitat',
                dataIndex: 'expirationDate',
                width: '180px',
                //sorter: (a: any, b: any) => a.expirationDate.localeCompare(b.expirationDate),
                render: (expirationDate: any) => {
                    if(moment(expirationDate).format('DD/MM/YYYY') != '01/01/0001')
                    {
                        return (
                            <div>{moment(expirationDate).format('DD/MM/YYYY')}</div>
                        )
                    }
                }
            }
        ];

        return (
          <Table
            showSorterTooltip={false}
            bordered={false}
            columns={subColumns.filter(item => !item.hidden)} 
            dataSource={record.children.filter((x:any) => x.display === true)}
            pagination={false}
          />
        );
      };


      const filter = (filters: any) => {
        let auxEvents = [...resources];
        auxEvents.map((bigEvent:any) => {
            bigEvent.children.map((event: any) => {
            if (filters.name != null && filters.name != '' && normalizeTxt(event.document.name).includes(normalizeTxt(filters.name)) == false)
            {
                event.display = false;
            }
            else if (filters.summary != null && filters.summary != '' && normalizeTxt(event.summary).includes(normalizeTxt(filters.summary)) == false)
            {
                event.display = false;
            }
            else if (filters.internalCode != null && filters.internalCode != '' && normalizeTxt(event.internalCode).includes(normalizeTxt(filters.internalCode)) == false)
            {
                event.display = false;
            }
            else if (filters.documentType != null && filters.documentType != 0 && (event.documentType == null || event.documentType != null && parseInt(filters.documentType) != event.documentType.id))
            {
                event.display = false;
            }
            else if (filters.initDate != null && (event.documentDate == null || moment(filters.initDate).diff(moment(event.documentDate).format('YYYY-MM-DD'), 'days') > 0 ))
            {
                event.display = false;
            }
            else if (filters.endDate != null && (event.documentDate == null || moment(filters.endDate).diff(moment(event.documentDate).format('YYYY-MM-DD'), 'days') < 0 ))
            {
                event.display = false;
            }
            else if (filters.expInitDate != null && (event.expirationDate == null || moment(filters.expInitDate).diff(moment(event.expirationDate).format('YYYY-MM-DD'), 'days') > 0 ))
            {
                event.display = false;
            }
            else if (filters.expEndDate != null && (event.expirationDate == null || moment(filters.expEndDate).diff(moment(event.expirationDate).format('YYYY-MM-DD'), 'days') < 0 ))
            {
                event.display = false;
            }
            else if (filters.createdBy != null && filters.createdBy != '' && (event.createdBy == null || event.createdBy.value == null ||event.createdBy.value == "" || normalizeTxt(event.createdBy.value).includes(normalizeTxt(filters.createdBy)) == false))
            {
                event.display = false;
            }
            else
            {
                event.display = true;
            }
            })

            if(bigEvent.children.filter((x:any) => x.display === true).length == 0)
            {
                bigEvent.display = false;
            }
            else
            {
                bigEvent.display = true;
            }
        })
        setResources(auxEvents);
    }

    if(loading != 0){
        return (
            <Container>
                <div className={styles.titleContainer}>
                    <Col><div className='h2'>Publicats</div></Col>
                    <Col>
                        <div className={styles.btnRefresh}>
                            {minutesAgo == 0 ? null : <BtnLink path='/Esborrany' iconLeft={<TfiReload />}
                            text={' Actualitzat fa '+ minutesAgo +' minuts '}/> }
                        </div>
                    </Col>                
                    <Col></Col>
                </div>
                <div className={styles.contentCompBG}>
                <TableMenu childFilter={<PublicatsFilter filterFunction={filter} documentTypeOptions={filterData.tipusDocument} />} resourceData={resources.filter((x:any) => x.display === true)} columns={columns} expandable={true} expandedRowRender={expandedRowRender} />
                </div>
            </Container>
        )
    }
    else {
        return (
            <Container>
            <div className={styles.titleContainer}>
                <Col><div className='h2'>Publicats</div></Col>
                <Col></Col>                
                <Col></Col>
            </div>
            <div className={styles.contentCompBG}>
                <div className="app-spinner">
                        <img width={150} src={loadingLogo} alt="loading..." />
                    </div>
            </div>
            </Container> 
        );
    }
}

export default Publicats