import BtnLinkDropdownProps from "./BtnLinkDropdownProps";
import styles from './BtnLinkDropdown.module.sass';
import Button from 'react-bootstrap/Button';
import { Link, useLocation } from "react-router-dom";

function BtnLinkDropdown(props:BtnLinkDropdownProps) {
    return(
        <Link to={props.path} state={props.state} className={styles.link} onClick={props.onClick}>
            <Button className={styles.btn_link}>
                <span>{props.iconLeft}</span>&nbsp;
                <span>{props.text}</span>&nbsp;
                <span>{props.iconRight}</span>
            </Button>
        </Link>        
    )
}

export default BtnLinkDropdown