import React from "react";
import ImageUploaderProps from "./ImageUploaderProps";
import { BsTrash } from "react-icons/bs";
import { AiOutlineFileWord, AiOutlineFile, AiOutlineFilePdf, AiOutlineFileZip} from "react-icons/ai";

//SCSS//
import styles from './ImageUploader.module.sass'
import BtnDelete from "../../layout/buttons/BtnDelete/BtnDelete";
import BtnLink from "../../layout/buttons/link/BtnLink";

class ImageUploader1 extends React.Component<ImageUploaderProps, {
}> 
{
  constructor(props: any) {
    super(props);
    this.state = {
    }
  }

  render () {
    return (
      <div>
        <div id='containerForm'>
                <label className={styles.uploadButton}>
                    <input type="file" onChange={this.props.changeSelected}/>
                    Pujar arxiu
                </label>
                <input
                    id="upfile"
                    type="file"
                    onChange={this.props.changeSelected}
                    className = {styles.uploadButton}
                    placeholder='Seleccionar imatge'
                    accept="application/pdf"
                    capture="environment"
                />
                </div>
                <p></p>
                <div className={styles.fileContainers}>
                  
                  {this.props.selectedFile ? this.props.selectedFile.map((x:any) =>
                  {
                    if(x != null && x.fileUrl != null)
                    {
                      x.name = x.fileName;
                      return (
                        <div className={styles.fileContainer}>
                          <BtnLink iconLeft={x.name.split('.')[1].includes('doc') == true ? 
                          <AiOutlineFileWord /> : x.name.split('.')[1].includes('pdf') == true ? 
                          < AiOutlineFilePdf /> : x.name.split('.')[1].includes('zip') == true ? 
                          < AiOutlineFileZip /> : < AiOutlineFile />}
                          text={x.name} path={x.fileUrl} />
                          <BtnDelete onClick={() => {
                                                this.props.removeFile(x)
                                            }} icon={<BsTrash size={20} />}></BtnDelete>
                        </div>
                      )
                    }
                    else if (x != null && x.name != null){
                      return (
                        <div className={styles.fileContainer}>
                          <BtnLink iconLeft={x.name.split('.')[1].includes('doc') == true ? 
                          <AiOutlineFileWord /> : x.name.split('.')[1].includes('pdf') == true ? 
                          < AiOutlineFilePdf /> : x.name.split('.')[1].includes('zip') == true ? 
                          < AiOutlineFileZip /> : < AiOutlineFile />}
                          text={x.name} />
                          <BtnDelete onClick={() => {
                                                this.props.removeFile(x)
                                            }} icon={<BsTrash size={20} />}></BtnDelete>
                        </div>
                      )
                    }
                  }
                    
                  ) : null}
                
                </div>
      </div>
    )
  }
}

export default ImageUploader1;