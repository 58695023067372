import React from 'react';
import InputProps from './TableMenuProps';
import { BsFunnel } from "react-icons/bs";
import BtnPrimary from '../../layout/buttons/primary/BtnPrimary';
import { Table } from 'antd';
import "./TableMenu.sass";
import style from "./TableMenu.module.sass";

class TableMenu extends React.Component<InputProps, {
    toggle: boolean;
}> {
    constructor(props: any) {
        super(props);
        this.state = {
          toggle: true,
        }
      }      

    render() {
        return (
            <div>
                <div className={style.tableFilter}>
                    <div className={style.Results}>
                        {this.props.resourceData.length ? this.props.resourceData.length : 0} resultats
                    </div>

                    <div className={style.Filter}>
                            <div className={style.btnFilter}>
                                {
                                    this.props.showFilter === false ? null : 
                                    <BtnPrimary iconRight={<BsFunnel size={20}/>} onClick={()=>{this.setState({ toggle: !this.state.toggle})}}></BtnPrimary>   
                                }
                            </div>
                        </div>
                    
                </div>
                <div className="contentFilter" id="filterBox" hidden={this.state.toggle}>
                    {this.props.childFilter}
                </div>
                <p></p>
                    <div id="table-container" className="table-responsive" style={{width:'100%'}} >
                        {
                            this.props.expandable == true
                            ?
                            <Table
                                showHeader={false}
                                showSorterTooltip={false}
                                bordered={false}
                                dataSource={this.props.resourceData}
                                pagination={{pageSize: this.props.pageSize, position: ['bottomCenter']}}
                                className="components-table-demo-nested"
                                columns={this.props.columns}
                                expandedRowRender={this.props.expandedRowRender}
                                onExpand={this.props.handleExpand}
                            />
                            :
                            <Table
                                showSorterTooltip={false}
                                bordered={false}
                                columns={this.props.columns} 
                                dataSource={this.props.resourceData}
                                pagination={{pageSize: this.props.pageSize, position: ['bottomCenter']}}
                            />
                        }
                        
                    </div>                
                </div>
        );
    }
}

export default TableMenu;