import HeaderProps from "./HeaderProps";
import styles from './Header.module.sass';
import logotipo from './../../../assets/icons/logotipo.svg';
import BtnPrimary from '../buttons/primary/BtnPrimary';
// import { HiOutlineUserCircle } from "react-icons/hi";
import { FaCalendarAlt, FaBookOpen } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { Dropdown } from "react-bootstrap";
import Icon from "../../../assets/images/UserIcon.png";
import './Header.sass';

function Header(props: any) {
    return (
        <div className={styles.navbar}>
            <div id="hamburguerMenu">
                <BtnPrimary text={"Menu"} onClick={props.onClick} /> 
            </div>
            <div>
                <a href="/"><img src={logotipo} className="logotipo" /></a>
            </div>
            <div id="signIn">
                <Dropdown>
                    <Dropdown.Toggle>
                    <img src={Icon}/> <span className={styles.userName}>{props.userName}</span> <span className={styles.shortUserName}>{props.userName.slice(0,6) + '...'}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={props.logoutFunc}>{<FiLogOut  size="18"/>} Tancar sessió</Dropdown.Item>
                        <Dropdown.Item onClick={() => { window.open(process.env.REACT_APP_USER_GUIDE, '_blank') }}>{<FaBookOpen size="18"/>} Guia per a l'usuari</Dropdown.Item>
                        <Dropdown.Item onClick={() => { window.open(process.env.REACT_APP_CALENDAR, '_blank') }}>{<FaCalendarAlt size="18"/>} Calendari de publicació</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default Header