import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { BsFileEarmarkBreak, BsFileEarmark, BsFileEarmarkCheck, BsFileEarmarkX, BsFileEarmarkPlus, BsGrid1X2 } from "react-icons/bs";

//SASS//
import styles from "./NavBar.module.sass"

const sidebarNavItems = [
    // {
    //   path: "/",
    //   display: "Dashboard",
    //   icon: <BsGrid1X2 size={24} />
    // },
    {
      path: "/Create",
      display: "Nou Document",
      icon: <BsFileEarmarkPlus size={24} />
    },
    {
      path: "/Esborrany",
      display: "Esborrany",
      icon: <BsFileEarmark size={24} />
    },
    {
      path: "/",
      display: "En curs",
      icon: <BsFileEarmarkBreak size={24} />
    },
    {
        path: "/Publicats",
        display: "Publicats",
        icon: <BsFileEarmarkCheck size={24} />
    },
    {
        path: "/Denegats",
        display: "Denegats",
        icon: <BsFileEarmarkX size={24} />
    }
  ] 

function NavBar(props: any) {

  const [counters, setCounters] = useState(null as any)

  useEffect (() => {
    props.apiService.GetDocumentsCounters()
      .then((response: any) => {
        setCounters(response.data)
      })
  }, [])

    return (
    <div className={styles.containerSideBarMenu}>
      <nav className={styles.navbar__menu}>
        <ul className={styles.navbar__list}>
        {
          sidebarNavItems.map((item, i) => (
            <li className={styles.nav}
            onClick={props.openMenu}>
              <NavLink 
              to={item.path} 
              className={({ isActive }) => (isActive ? styles.navbar__liBoxActive : styles.navbar__liBox)}
              >
                <div className={styles.item__elements}>
                    <div>{item.icon} </div>
                    <div>{item.display}</div>
                    { counters != null ? 
                      item.display == "Esborrany" ?
                      <div>{"(" + counters.draftDocuments  + ")"}</div>
                      : item.display == "En curs" ?
                      <div>{"(" + counters.pendingDocuments  + ")"}</div>
                      : item.display == "Publicats" ?
                      <div>{"(" + counters.publishedDocuments  + ")"}</div>
                      : item.display == "Denegats" ?
                      <div>{"(" + counters.deniedDocuments  + ")"}</div>
                      : null
                    : null
                    }
                    
                </div>
              </NavLink>
            </li>
          ))
          }
        </ul>
      </nav>
    </div>        
    )
}

export default NavBar