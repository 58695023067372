import React from 'react';
import AlertDangerProps from './AlertDangerProps';
import { BsExclamationTriangle } from "react-icons/bs";

class AlertDanger extends React.Component<AlertDangerProps, {}> {
    render() {
        return (
            <div className='alert alert-danger d-flex align-items-center' role="alert">
                <BsExclamationTriangle />
                <div className='ml-1' style={{paddingLeft: "10px"}}>
                    {this.props.message}
                </div>
            </div>
        );
    }
}

export default AlertDanger;