import { Col, Container, Dropdown, DropdownButton, Modal } from "react-bootstrap"
import { BsThreeDotsVertical, BsTrash, BsPencil, BsFileEarmarkText, BsArrowRight} from "react-icons/bs"
import '../../styles/Table.sass';
import styles from './Esborrany.module.sass';
import { useEffect, useState } from "react"
import TableMenu from "../../components/table/TableMenu/TableMenu"
import BtnLink from "../../components/layout/buttons/link/BtnLink"
import{TfiReload} from 'react-icons/tfi'
import EsborranyFilter from "../../components/forms/EsborranyFilter/EsborranyFilter";
import loadingLogo from '../../assets/loading/loading.gif';
import BtnLinkDropdown from "../../components/layout/buttons/linkDropdown/BtnLinkDropdown"
import moment from "moment"
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary"
import AlertWarning from "../../components/Alerts/AlertWarning/AlertWarning"
import EsborranyProps from "./EsborranyProps";
import { SlPaperPlane } from "react-icons/sl";


function Esborrany(props: EsborranyProps) {

    const [minutesAgo, setminutesAgo] = useState(0);
    const [resources, setResources] = useState([] as any);
    const [loading, setLoading] = useState(0); // integer state
    const [filterData, setFilterData] = useState([] as any);
    const [isSupervisor, setIsSupervisor] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSend = (id: any) => {
        setLoading(0);
        props.apiService.SendDraft(id)
        .then((response1: any) => {
            props.apiService.GetDrafts()
            .then((response: any) => {
                let auxData: any[] = [];
                response.data.map((item:any) => {
                    if(item.id != null){
                        auxData.push({
                            code: item.internalCode != null ? item.internalCode : "", 
                            document: {
                                id: parseInt(item.id),
                                name: item.name,
                            },           
                            summary: item.summary != null ? item.summary : "",
                            documentType: item.documentType.id ,
                            documentDate: item.documentDate,
                            item: '',
                            createdBy: item.createdBy,
                            display: true
                        })
                    }                
                })
                setResources(auxData);
                setLoading(1);
            })
            .catch((error: any) => {
                setLoading(1);
                console.log(error)
            })        
        })
        .catch((error: any) => {
            console.log(error)
            setLoading(1);
        })
    }

    useEffect(() => {
        props.apiService.GetDrafts()
        .then((response: any) => {
            let auxData: any[] = [];
            setIsSupervisor(response.data.isSupervisor);
            response.data.documents.map((item:any) => {
                if(item.id != null){
                    auxData.push({
                        code: item.internalCode != null ? item.internalCode : "", 
                        document: {
                            id: parseInt(item.id),
                            name: item.name,
                        },           
                        summary: item.summary != null ? item.summary : "",
                        documentType: item.documentType.id ,
                        documentDate: item.documentDate,
                        item: '',
                        display: true,
                        createdBy: item.createdBy,
                        creationDate: item.creationDate
                    })
                }                
            })
            auxData = auxData.sort((a,b) => {
                return a.document.id - b.document.id
            }).reverse();
            setResources(auxData);
            setLoading(1);
            props.apiService.GetTaxonomyFields()
            .then((response: any) => {
                setFilterData(response.data);
            })
            .catch((error: any) => {
                console.log(error)
            })
        })
        .catch((error: any) => {
            console.log(error)
        })        
    }, []);

    const normalizeTxt = (str: any) => {
        str = str.toUpperCase();
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 

    const DeleteDocumentFunction = (id: number) => {
        setShow(false);
        setLoading(0);
        props.apiService.DeleteDocument(id)
        .then((response: any) => {
            props.apiService.GetDrafts()
            .then((response: any) => {
                let auxData: any[] = [];
                response.data.map((item:any) => {
                    if(item.id != null){
                        auxData.push({
                            code: item.internalCode != null ? item.internalCode : "", 
                            document: {
                                id: parseInt(item.id),
                                name: item.name,
                            },           
                            summary: item.summary != null ? item.summary : "",
                            documentType: item.documentType.id ,
                            documentDate: item.documentDate,
                            item: '',
                            createdBy: item.createdBy,
                            display: true
                        })
                    }                
                })
                setResources(auxData);
                setLoading(1);
            })
            .catch((error: any) => {
                console.log(error)
            })        
        })
        .catch((error: any) => {
            console.log(error)
        })
    }
    
    const columns: any[] = [
        {
            title: 'Codi intern',
            dataIndex: 'code',
            width: '250px',
            sorter: (a: any, b: any) => a.code.localeCompare(b.code),
        },
        {
            title: 'Nom',
            dataIndex: 'document',
            width: '250px',
            sorter: (a: any, b: any) => a.document.name.localeCompare(b.document.name),
            render: (document: any) => (
            <BtnLink state={{id: document.id}} path={`/Esborrany/Detall`} text={document.name}></BtnLink>
            )
        },
        {
            title: 'Sumari',
            dataIndex: 'summary',
            width: '500px',
            sorter: (a: any, b: any) => a.summary.localeCompare(b.summary),
        },
        {
            
            title: 'Publicador',
            dataIndex: 'createdBy',
            width: '180px',
            key: 8,
            sorter: (a: any, b: any) => a.createdBy.value.localeCompare(b.createdBy.value),
            render: (createdBy: any) => (
                <div>{createdBy != null && createdBy.value != null ? createdBy.value : ""}</div>
                ),
            hidden: (isSupervisor == true ? false : true),
        },
        {
            title: '',
            dataIndex: 'document',
            width: '5px',
            render: (document: any) => (
                <DropdownButton title={ <BsThreeDotsVertical /> } >
                    <BtnLinkDropdown state={{id: document.id}} path={`/Esborrany/Detall`} iconLeft={<BsFileEarmarkText/>} text={"Detall"}></BtnLinkDropdown>
                    <BtnLinkDropdown state={{id: document.id}} path={`/Esborrany/Editar`} iconLeft={<BsPencil /> } text={"Editar"}></BtnLinkDropdown>
                    
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                        <Modal.Title>Suprimeix</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AlertWarning message="Està segur de voler eliminar el document?" />
                        </Modal.Body>
                        <Modal.Footer>
                        <BtnPrimary text="Suprimeix ara" iconRight={<BsArrowRight />} onClick={ () => { DeleteDocumentFunction(document.id) } } />
                        </Modal.Footer>
                    </Modal>
                    <BtnLinkDropdown onClick={() => {handleSend(document.id)}} iconLeft={<SlPaperPlane /> } text={"Enviar"}></BtnLinkDropdown>
                    <BtnLinkDropdown onClick={handleShow} iconLeft={<BsTrash />} text={"Suprimeix"}></BtnLinkDropdown>
                </DropdownButton>      
              )
        }
    ];
    

    const filter = (filters: any) => {
        console.log(filters)
        let auxEvents = [...resources];
        auxEvents.map((event:any) => {
            if (filters.name != null && filters.name != '' && event.document.name.toUpperCase().includes(filters.name.toUpperCase()) == false)
            {
                event.display = false;
            }
            else if (filters.summary != null && filters.summary != '' && event.summary.toUpperCase().includes(filters.summary.toUpperCase()) == false)
            {
                event.display = false;
            }
            else if (filters.internalCode != null && filters.internalCode != '' && event.code.toUpperCase().includes(filters.internalCode.toUpperCase()) == false)
            {
                event.display = false;
            }
            else if (filters.documentType != null && filters.documentType != 0 && (event.documentType == null || event.documentType != null && parseInt(filters.documentType) != event.documentType))
            {
                event.display = false;
            }
            else if (filters.initDate != null && (event.documentDate == null || moment(filters.initDate).diff(event.documentDate, 'days') > 0 ))
            {
                event.display = false;
            }
            else if (filters.endDate != null && (event.documentDate == null || moment(filters.endDate).diff(event.documentDate, 'days') < 0 ))
            {
                event.display = false;
            }
            else if (filters.createdBy != null && filters.createdBy != '' && (event.createdBy == null || event.createdBy.value == null ||event.createdBy.value == "" || normalizeTxt(event.createdBy.value).includes(normalizeTxt(filters.createdBy)) == false))
            {
                event.display = false;
            }
            else
            {
                event.display = true;
            }
        })
        setResources(auxEvents);
    }

    if(loading != 0){
    return (
        <Container>
            <div className={styles.titleContainer}>
                <Col><div className='h2'>Esborrany</div></Col>
                <Col>
                    <div className={styles.btnRefresh}>
                        {minutesAgo == 0 ? null : <BtnLink path='/Esborrany' iconLeft={<TfiReload />}
                        text={' Actualitzat fa '+ minutesAgo +' minuts '}/> }
                    </div>
                </Col>                
                <Col></Col>
            </div>
            <div className={styles.contentCompBG}>
                <TableMenu childFilter={<EsborranyFilter filterFunction={filter} documentTypeOptions={filterData.tipusDocument}/>} resourceData={resources.filter((x:any) => x.display === true)} columns={columns.filter(item => !item.hidden)} />
            </div>
        </Container>
    )
    }
    else {
        return (
            <Container>
            <div className={styles.titleContainer}>
                <Col><div className='h2'>Esborrany</div></Col>
                <Col></Col>                
                <Col></Col>
            </div>
            <div className={styles.contentCompBG}>
                <div className="app-spinner">
                        <img width={150} src={loadingLogo} alt="loading..." />
                    </div>
            </div>
            </Container> 
        );
    }
}

export default Esborrany