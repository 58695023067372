import React from 'react';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import AlertWarningProps from '../AlertWarning/AlertWarningProps';

class AlertWarning extends React.Component<AlertWarningProps, {}> {
    render() {
        return (
            <div className='alert alert-warning d-flex align-items-center'>
                <BsFillExclamationTriangleFill />
                <div className='ml-1' style={{paddingLeft: "10px"}}>
                    {this.props.message}
                </div>
            </div>
        );
    }
}

export default AlertWarning;